<template>
    <v-dialog v-model="visible" persistent width="900px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro' }} de Público</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                :style="`height: ${$vuetify.breakpoint.height - 400}px; overflow-y: auto;`"
            >
                <v-text-field
                    label="Nome"
                    v-model="form.nome"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                />
                <!-- <v-btn-toggle
                    v-model="form.fonte"
                    rounded
                    :style="cssVars"
                    class="mt-2"
                >
                    <v-btn value="personalizado" style="font-size: 10px; height: 60px; width: 30%;">
                        <v-col cols="12">
                            <v-row
                                width="100%"
                                style="place-content: center;"
                            >
                                <v-icon class="mr-2" :color="form.fonte === 'personalizado' ? '#fff' : '#000'">
                                    mdi-account-filter-outline
                                </v-icon>
                            </v-row>
                            <v-row width="100%" style="place-content: center">
                                <span><b>Personalizado</b></span>
                            </v-row>
                        </v-col>
                    </v-btn>

                    <v-btn value="csv" style="font-size: 10px; height: 60px; width: 30%;">
                        <v-col cols="12">
                            <v-row
                                width="100%"
                                style="place-content: center;"
                            >
                                <v-icon class="mr-2" :color="form.fonte === 'csv' ? '#fff' : '#000'">
                                    mdi-file-excel-outline
                                </v-icon>
                            </v-row>
                            <v-row width="100%" style="place-content: center">
                                <span><b>CSV</b></span>
                            </v-row>
                        </v-col>
                    </v-btn>
                </v-btn-toggle> -->
                <div v-if="form.fonte === 'csv'" class="mt-2" style="border: solid 1px #999; border-radius: 5px;">
                    <b>Arquivo CSV</b>
                    <FileInput ref="file" v-model="form.arquivo" />
                </div>

                <div v-if="form.fonte === 'personalizado'">
                    <div class="my-2" style="display: flex; align-items: center;">
                        Clientes que combinam com
                        <v-select
                            :items="['Qualquer', 'Todas']"
                            v-model="form.operador_condicional"
                            dense
                            outlined
                            hide-details
                            class="mx-2"
                            style="max-width: 130px;"
                        />
                        uma das seguintes condições:
                    </div>
                    <div>
                        <div
                            v-for="(condicao, i) in form.condicoes"
                            :key="i"
                            style="display: flex; align-items: baseline;"
                            class="my-2"
                        >
                            <b class="mr-2">{{ form.operador_condicional === 'Qualquer' ? 'OU' : 'E' }}</b>
                            <v-select
                                @change="form.condicoes[i][form.condicoes[i].type] = {}"
                                :items="condicoes"
                                v-model="form.condicoes[i].type"
                                dense
                                outlined
                                hide-details
                                class="mx-2"
                                style="max-width: 160px;"
                            />
                            <div v-if="form.condicoes[i].type === 'PEDIDOS'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].PEDIDOS.operador_quantidade"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_quantidade)"
                                    v-model="form.condicoes[i].PEDIDOS.quantidade_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_quantidade)"
                                    v-model="form.condicoes[i].PEDIDOS.quantidade_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="dateOptions"
                                    v-model="form.condicoes[i].PEDIDOS.operador_periodo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['ANTES_DE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_periodo)"
                                    v-model="form.condicoes[i].PEDIDOS.data_inicial"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                                <v-text-field
                                    v-if="['DEPOIS_DE', 'ENTRE'].includes(form.condicoes[i].PEDIDOS.operador_periodo)"
                                    v-model="form.condicoes[i].PEDIDOS.data_final"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                            </div>
                            <!-- <div v-if="form.condicoes[i].type === 'CLIENTE_POTENCIAL'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="booleanOptions"
                                    v-model="form.condicoes[i].CLIENTE_POTENCIAL.valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                            </div> -->
                            <div v-if="form.condicoes[i].type === 'VALOR_GASTO'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].VALOR_GASTO.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_valor)"
                                    v-model="form.condicoes[i].VALOR_GASTO.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_valor)"
                                    v-model="form.condicoes[i].VALOR_GASTO.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="dateOptions"
                                    v-model="form.condicoes[i].VALOR_GASTO.operador_periodo"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['ANTES_DE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_periodo)"
                                    v-model="form.condicoes[i].VALOR_GASTO.data_inicial"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                                <v-text-field
                                    v-if="['DEPOIS_DE', 'ENTRE'].includes(form.condicoes[i].VALOR_GASTO.operador_periodo)"
                                    v-model="form.condicoes[i].VALOR_GASTO.data_final"
                                    type="date"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 160px;"
                                />
                            </div>

                            <div v-if="form.condicoes[i].type === 'DIAS_SEM_COMPRAR'" style="display: flex; flex-wrap: wrap;">
                                <v-select
                                    @change="$forceUpdate()"
                                    :items="numberOptions"
                                    v-model="form.condicoes[i].DIAS_SEM_COMPRAR.operador_valor"
                                    dense
                                    outlined
                                    hide-details
                                    class="mx-2"
                                    style="max-width: 140px;"
                                />
                                <v-text-field
                                    v-if="['MAIOR_QUE', 'ENTRE'].includes(form.condicoes[i].DIAS_SEM_COMPRAR.operador_valor)"
                                    v-model="form.condicoes[i].DIAS_SEM_COMPRAR.valor_inicial"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                                <v-text-field
                                    v-if="['MENOR_QUE', 'ENTRE'].includes(form.condicoes[i].DIAS_SEM_COMPRAR.operador_valor)"
                                    v-model="form.condicoes[i].DIAS_SEM_COMPRAR.valor_final"
                                    v-mask="['####']"
                                    outlined
                                    dense
                                    hide-details
                                    style="max-width: 60px;"
                                />
                            </div>
                            <v-btn @click="form.condicoes.splice(i, 1)" small color="error" icon>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-btn color="primary" @click="addCondicao" class="mt-5">
                        <v-icon>mdi-plus</v-icon>&nbsp;Adicionar Condição
                    </v-btn>
                </div>
            </v-card-text>
            <div class="ml-4">
                <span class="text-subtitle">Tamanho do público: <b>{{ form.tamanho_publico || 0 }}</b></span>
                <v-btn @click="calcularTamanhoPublico" icon color="primary" class="ml-2" :loading="tamanhoLoading">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </div>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import FileInput from '@/components/FileInput';

export default {
    name: 'Publico',

    components: {
        FileInput,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: Object,
        },
    },

    data: () => ({
        loading: false,
        tamanhoLoading: false,
        form: {},
        canais: ['whatsapp'],
        publicos: [],
        condicoes: [
            { value: 'PEDIDOS', text: 'Pedidos' },
            { value: 'VALOR_GASTO', text: 'Valor Gasto' },
            { value: 'DIAS_SEM_COMPRAR', text: 'Dias sem Comprar' },
            // { value: 'VISITAS', text: 'Visitas' },
            // { value: 'CLIENTE_POTENCIAL', text: 'Cliente Potencial' },
        ],
        numberOptions: [
            { value: 'ENTRE', text: 'Entre' },
            { value: 'MAIOR_QUE', text: 'Maior que' },
            { value: 'MENOR_QUE', text: 'Menor que' },
        ],
        dateOptions: [
            { value: 'ENTRE', text: 'Entre' },
            { value: 'ANTES_DE', text: 'Antes de' },
            { value: 'DEPOIS_DE', text: 'Depois de' },
        ],
        booleanOptions: [
            { value: true, text: 'É' },
            { value: false, text: 'Não é' },
        ],
    }),

    mounted() {
        this.reset();
    },

    computed: {
        cssVars() {
            return {
                '--bg-color': '#3874d0',
                '--color': '#fff',
                'width': '100%',
            };
        },
    },

    watch: {
        formData(value) {
            value
                ? (this.form = JSON.parse(JSON.stringify(value)))
                : this.reset();
        },
    },

    methods: {
        reset() {
            this.form = {
                nome: '',
                fonte: 'personalizado',
                mensagem: '',
                operador_condicional: 'Qualquer',
                tamanho_publico: null,
                condicoes: [],
            };
        },

        validate() {
            if (!this.form.nome) {
                this.notify('Preencha o nome da campanha', 'warning');
                return;
            }

            if (!this.form.condicoes.length) {
                this.notify('Nenhuma condição adicionada', 'warning');
                return;
            }

            if (!this.validateCondicoes()) {
                this.notify('Preencha todas as condições', 'warning');
                return;
            }

            return true;
        },

        async salvar() {
            await this.calcularTamanhoPublico();

            if (this.form.id) {
                this.loading = true;
                this.$http.put(`publicos/${this.form.id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('publicos', this.form).then(resp => {
                this.$emit('onCadastro', resp.data.data);
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        validateCondicoes() {
            for (const condicao of this.form.condicoes) {
                if (!condicao.type) return false;
                if (condicao.type === 'PEDIDOS') {
                    if (!condicao.PEDIDOS.operador_quantidade || 
                        (['MAIOR_QUE', 'ENTRE'].includes(condicao.PEDIDOS.operador_quantidade) && !condicao.PEDIDOS.quantidade_inicial) || 
                        (['MENOR_QUE', 'ENTRE'].includes(condicao.PEDIDOS.operador_quantidade) && !condicao.PEDIDOS.quantidade_final) ||
                        !condicao.PEDIDOS.operador_periodo ||
                        (['ANTES_DE', 'ENTRE'].includes(condicao.PEDIDOS.operador_periodo) && !condicao.PEDIDOS.data_inicial) || 
                        (['DEPOIS_DE', 'ENTRE'].includes(condicao.PEDIDOS.operador_periodo) && !condicao.PEDIDOS.data_final)
                    ) {
                        return false;
                    }
                } else if (condicao.type === 'VALOR_GASTO') {
                    if (!condicao.VALOR_GASTO.operador_valor || 
                        (['MAIOR_QUE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_valor) && !condicao.VALOR_GASTO.valor_inicial) || 
                        (['MENOR_QUE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_valor) && !condicao.VALOR_GASTO.valor_final) ||
                        !condicao.VALOR_GASTO.operador_periodo ||
                        (['ANTES_DE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_periodo) && !condicao.VALOR_GASTO.data_inicial) || 
                        (['DEPOIS_DE', 'ENTRE'].includes(condicao.VALOR_GASTO.operador_periodo) && !condicao.VALOR_GASTO.data_final)
                    ) {
                        return false;
                    }
                } else if (condicao.type === 'DIAS_SEM_COMPRAR') {
                    if (!condicao.DIAS_SEM_COMPRAR.operador_valor || 
                        (['MAIOR_QUE', 'ENTRE'].includes(condicao.DIAS_SEM_COMPRAR.operador_valor) && !condicao.DIAS_SEM_COMPRAR.valor_inicial) || 
                        (['MENOR_QUE', 'ENTRE'].includes(condicao.DIAS_SEM_COMPRAR.operador_valor) && !condicao.DIAS_SEM_COMPRAR.valor_final)
                    ) {
                        return false;
                    }
                }
            }
            return true;
        },

        addCondicao() {
            this.form.condicoes.push({});
        },

        async calcularTamanhoPublico() {
            const invalid = !this.validate();
            console.log('invalid ', invalid)
            if (invalid) {
                return;
            }

            this.tamanhoLoading = true;
            await this.$http.post('publicos/obter-tamanho', this.form).then(resp => {
                this.form.tamanho_publico = resp.data.data;
                this.notify('Tamanho do publico atualizado!');
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.tamanhoLoading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}
</style>
